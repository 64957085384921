export default async ({ isHMR, route, redirect, error }) => {
  if (isHMR) return

  if (/^locale-(article|section|categories)-id-slug$/.test(route.name)) {
    if (/^\d+$/.test(route.params.id)) {
      return // Id is already a number
    }

    const locale = route.params.locale
    const name = route.name.split('-')[1]
    const id = parseInt(route.params.slug, 10) || parseInt(route.params.id, 10)

    if (isNaN(id)) {
      return error({
        message: 'This page could not be found',
        statusCode: 404,
      })
    }

    redirect(`/${locale}/${name}/${id}`)
  }
}
