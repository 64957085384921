const middleware = {}

middleware['locale'] = require('../middleware/locale.js')
middleware['locale'] = middleware['locale'].default || middleware['locale']

middleware['oldUrlRedirect'] = require('../middleware/oldUrlRedirect.js')
middleware['oldUrlRedirect'] = middleware['oldUrlRedirect'].default || middleware['oldUrlRedirect']

middleware['privacyPolicyRedirect'] = require('../middleware/privacyPolicyRedirect.js')
middleware['privacyPolicyRedirect'] = middleware['privacyPolicyRedirect'].default || middleware['privacyPolicyRedirect']

export default middleware
