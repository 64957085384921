import { HotmartRum } from '@hotmart-org-ca/hot-observability-js/rum/vue'

export default async function (ctx, inject) {
  const config = Object.assign({}, {
  "release": "app-helpcenter@1.5.24",
  "disabled": false,
  "dsn": "https://626d0a5b5e00379d1336e3d33e5ad63c@o49094.ingest.us.sentry.io/4506937893126144",
  "environment": "production",
  "sourceMapStyle": "source-map",
  "publishRelease": {
    "org": "hotmart",
    "project": "app-helpcenter",
    "authToken": "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",
    "include": "/drone/src/.nuxt/dist",
    "urlPrefix": "~/",
    "sourcemaps": {
      "ignore": [
        "node_modules/**/*",
        "assets"
      ]
    },
    "ext": [
      "js",
      "map"
    ],
    "deploy": {
      "env": "production",
      "name": "app-helpcenter@1.5.24"
    }
  },
  "vueOptions": {
    "tracing": true,
    "tracingOptions": {
      "hooks": [
        "mount",
        "update"
      ],
      "timeout": 2000,
      "trackComponents": true
    }
  },
  "vueRouterInstrumentationOptions": {
    "routeLabel": "name"
  }
})
  const { app } = ctx
  HotmartRum.init({ app,  ...config })

  inject('sentry', HotmartRum)
  ctx.$sentry = HotmartRum
}
