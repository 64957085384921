
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('casUser', ['userCas']),
  },
  mounted() {
    const captureBlipChatShowEvent = () => {
      this.$gtm.push({
        event: 'noninteraction',
        event_category: 'HelpCenter:Chat',
        event_action: 'Show:Chat',
        event_label: 'Floating button',
      })
    }
    const captureBlipChatAccessType = type => {
      this.$gtm.push({
        event: 'noninteraction',
        event_category: `HelpCenter:ChatAccess${type}`,
        event_action: 'Show:ChatType',
        event_label: 'Floating button',
      })
    }
    let accessType = this.userCas ? 'Cas' : 'Random'

    captureBlipChatAccessType(accessType)
    captureBlipChatShowEvent()
    this.$microfrontRenderer()
  },
}
