import { SPECIFICATION_TAG } from './constants'

export const SPECIFICATION = {
  PHYSICAL_PRODUCTS_TRACK_MY_ORDER: {
    key: 'physicalProductsTrackMyOrder',
    tag: SPECIFICATION_TAG.PHYSICAL_PRODUCTS_TRACK_MY_ORDER,
    slug: 'physical-products-track-my-order',
  },
  PHYSICAL_PRODUCTS_ORDER_DIDNT_ARRIVE: {
    key: 'physicalProductsOrderDidntArrive',
    tag: SPECIFICATION_TAG.PHYSICAL_PRODUCTS_ORDER_DIDNT_ARRIVE,
    slug: 'physical-products-order-didnt-arrive',
  },
  PHYSICAL_PRODUCTS_RETURNS_AND_REFUNDS: {
    key: 'physicalProductsReturnsAndRefunds',
    tag: SPECIFICATION_TAG.PHYSICAL_PRODUCTS_RETURNS_AND_REFUNDS,
    slug: 'physical-products-returns-and-refunds',
  },
  PHYSICAL_PRODUCTS_MORE_INFORMATION_ABOUT_THE_ORDER: {
    key: 'physicalProductsMoreInformationAboutTheOrder',
    tag: SPECIFICATION_TAG.PHYSICAL_PRODUCTS_MORE_INFORMATION_ABOUT_THE_ORDER,
    slug: 'physical-products-more-information-about-the-order',
  },
  PRODUCER_LEADS: {
    key: 'producerLeads',
    tag: SPECIFICATION_TAG.PRODUCER_LEADS,
    slug: 'producer-leads',
  },
  CART_ABANDONMENT: {
    key: 'cartAbandonment',
    tag: SPECIFICATION_TAG.CART_ABANDONMENT,
    slug: 'cart-abandonment',
  },
  PROMOTIONAL_MATERIAL: {
    key: 'promotionalMaterial',
    tag: SPECIFICATION_TAG.PROMOTIONAL_MATERIAL,
    slug: 'promotional-material',
  },
  STUDENT_REVIEWS: {
    key: 'studentReviews',
    tag: SPECIFICATION_TAG.STUDENT_REVIEWS,
    slug: 'student-reviews',
  },
  PROTECT_CONTENT: {
    key: 'protectContent',
    tag: SPECIFICATION_TAG.PROTECT_CONTENT,
    slug: 'protect-content',
  },
  SELL_PRODUCT_PACKAGES: {
    key: 'sellProductPackages',
    tag: SPECIFICATION_TAG.SELL_PRODUCT_PACKAGES,
    slug: 'sell-product-packages',
  },
  SALES_PAGE: {
    key: 'salesPage',
    tag: SPECIFICATION_TAG.SALES_PAGE,
    slug: 'sales-page',
  },
  CONTENT_DELIVERY: {
    key: 'contentDelivery',
    tag: SPECIFICATION_TAG.CONTENT_DELIVERY,
    slug: 'content-delivery',
  },
  CREDENTIALS: {
    key: 'credentials',
    tag: SPECIFICATION_TAG.CREDENTIALS,
    slug: 'credentials',
  },
  PRODUCER_MEMBERS_AREA: {
    key: 'producerMembersArea',
    tag: SPECIFICATION_TAG.PRODUCER_MEMBERS_AREA,
    slug: 'producer-members-area',
  },
  CHANGE_COURSE_FORMAT: {
    key: 'changeCourseFormat',
    tag: SPECIFICATION_TAG.CHANGE_COURSE_FORMAT,
    slug: 'change-course-format',
  },
  PROBLEMS_WITH_MY_BALANCE: {
    key: 'problemsWithMyBalance',
    tag: SPECIFICATION_TAG.PROBLEMS_WITH_MY_BALANCE,
    slug: 'problems-with-my-balance',
  },
  I_CANT_WITHDRAW: {
    key: 'iCantWithdraw',
    tag: SPECIFICATION_TAG.I_CANT_WITHDRAW,
    slug: 'i-cant-withdraw',
  },
  CHANGE_COMMISSION_BETWEEN_AFFILIATES: {
    key: 'changeCommissionBetweenAffiliates',
    tag: SPECIFICATION_TAG.CHANGE_COMMISSION_BETWEEN_AFFILIATES,
    slug: 'change-commission-between-affiliates',
  },
  CHANGE_REGISTRATION: {
    key: 'changeRegistration',
    tag: SPECIFICATION_TAG.CHANGE_REGISTRATION,
    slug: 'change-registration',
  },
  CHANGE_DOCUMENT_CNPJ_TO_CNPJ: {
    key: 'changeDocumentCNPJtoNewCNPJ',
    tag: SPECIFICATION_TAG.CHANGE_DOCUMENT_CNPJ_TO_CNPJ,
    slug: 'change-document-cnpj-to-cnpj',
  },
  CHANGE_DOCUMENT_CNPJ_TO_CPF: {
    key: 'changeDocumentCNPJtoCPF',
    tag: SPECIFICATION_TAG.CHANGE_DOCUMENT_CNPJ_TO_CPF,
    slug: 'change-document-cnpj-to-cpf',
  },
  CHANGE_DOCUMENT_CPF_TO_CPF: {
    key: 'changeDocumentCPFtoNewCPF',
    tag: SPECIFICATION_TAG.CHANGE_DOCUMENT_CPF_TO_CPF,
    slug: 'change-document-cpf-to-cpf',
  },
  CHANGE_EVENT_DATE: {
    key: 'changeEventDate',
    tag: SPECIFICATION_TAG.CHANGE_EVENT_DATE,
    slug: 'change-event-date',
  },
  CORRECT_REGISTRATION_DOCUMENTS: {
    key: 'correctRegistrationDocuments',
    tag: SPECIFICATION_TAG.CORRECT_REGISTRATION_DOCUMENTS,
    slug: 'correct-registration-documents',
  },
  CHANGE_REGISTRATION_COUNTRY: {
    key: 'changeRegistrationCountry',
    tag: SPECIFICATION_TAG.CHANGE_REGISTRATION_COUNTRY,
    slug: 'change-registration-country',
  },
  GENERAL_PROBLEMS_WITH_REGISTRATION_DATA: {
    key: 'generalProblemsWithRegistrationData',
    tag: SPECIFICATION_TAG.GENERAL_PROBLEMS_WITH_REGISTRATION_DATA,
    slug: 'general-problems-with-registration-data',
  },

  CHANGING_HOTMART_EMAIL: {
    key: 'changingHotmartEmail',
    tag: SPECIFICATION_TAG.CHANGING_HOTMART_EMAIL,
    slug: 'changing-hotmart-email',
  },
  CHANGE_REQUEST_REASON: {
    key: 'changeRequestReason',
    tag: SPECIFICATION_TAG.CHANGE_REQUEST_REASON,
    slug: 'change-request-reason',
  },
  RESET_PASSWORD: { key: 'resetPassword', tag: SPECIFICATION_TAG.RESET_PASSWORD, slug: 'reset-password' },
  CHANGE_REGISTRATION_LANGUAGE: {
    key: 'changeRegistrationLanguage',
    tag: SPECIFICATION_TAG.CHANGE_REGISTRATION_LANGUAGE,
    slug: 'change-registration-language',
  },
  OTHER_CHANGES: {
    key: 'otherChanges',
    tag: SPECIFICATION_TAG.OTHER_CHANGES,
    slug: 'change-registration-language',
    slug: 'other-changes',
  },
  REQUEST_REFUND: { key: 'requestRefund', tag: SPECIFICATION_TAG.REQUEST_REFUND, slug: 'request-refund' },
  REQUEST_REFUND_PRODUCER: {
    key: 'requestRefund',
    tag: SPECIFICATION_TAG.REQUEST_REFUND_PRODUCER,
    slug: 'request-refund-producer',
  },
  REQUEST_UNSUBSCRIBE_PRODUCER: {
    key: 'unsubscribe',
    tag: SPECIFICATION_TAG.REQUEST_UNSUBSCRIBE_PRODUCER,
    slug: 'request-unsubscribe-producer',
  },
  REQUEST_UNRECOGNIZED_CHARGE_BUYER: {
    key: 'unrecognizedCharge',
    tag: SPECIFICATION_TAG.REQUEST_UNRECOGNIZED_CHARGE_BUYER,
    slug: 'unrecognized-charge',
  },
  REQUEST_DUPLICATE_BILLING_BUYER: {
    key: 'duplicateBilling',
    tag: SPECIFICATION_TAG.REQUEST_DUPLICATE_BILLING_BUYER,
    slug: 'duplicate-billing',
  },
  TRACK_REFUND: { key: 'trackRefund', tag: SPECIFICATION_TAG.TRACK_REFUND, slug: 'track-refund' },
  CANCEL_SUBSCRIPTION: {
    key: 'cancelSubscription',
    tag: SPECIFICATION_TAG.CANCEL_SUBSCRIPTION,
    slug: 'cancel-subscription',
  },
  CANCEL_REFUND_REQUEST: {
    key: 'cancelRefundRequest',
    tag: SPECIFICATION_TAG.CANCEL_REFUND_REQUEST,
    slug: 'cancel-refund-request',
  },
  CANCELED_SUBSCRIPTION_STILL_BEING_CHARGED: {
    key: 'canceledSubscriptionStillBeingCharged',
    tag: SPECIFICATION_TAG.CANCELED_SUBSCRIPTION_STILL_BEING_CHARGED,
    slug: 'canceled-subscription-still-being-charged',
  },
  MY_PRODUCT_CONTENT: {
    key: 'myProductContent',
    tag: SPECIFICATION_TAG.MY_PRODUCT_CONTENT,
    slug: 'my-product-content',
  },
  CERTIFICATE: { key: 'certificate', tag: SPECIFICATION_TAG.CERTIFICATE, slug: 'certificate' },
  MY_PRODUCT_SOCIAL_MEDIA: {
    key: 'myProductSocialMedia',
    tag: SPECIFICATION_TAG.MY_PRODUCT_SOCIAL_MEDIA,
    slug: 'my-product-social-media',
  },
  INVOICE_EMISSION: { key: 'invoiceEmission', tag: SPECIFICATION_TAG.INVOICE_EMISSION, slug: 'invoice-emission' },
  BILLET_BANKING: { key: 'billetBanking', tag: SPECIFICATION_TAG.BILLET_BANKING, slug: 'billet-banking' },
  CREDIT_CARD: { key: 'creditCard', tag: SPECIFICATION_TAG.CREDIT_CARD, slug: 'credit-card' },
  NOT_FOUND_PAYMENT_METHOD: {
    key: 'notFoundPaymentMethod',
    tag: SPECIFICATION_TAG.NOT_FOUND_PAYMENT_METHOD,
    slug: 'not-found-payment-method',
  },
  BANK_DEBT: { key: 'bankDebt', tag: SPECIFICATION_TAG.BANK_DEBT, slug: 'bank-debt' },
  PIX: { key: 'pix', tag: SPECIFICATION_TAG.PIX, slug: 'pix' },
  INFO_ABOUT_SUBSCRIPTION_PAYMENT: {
    key: 'infoAboutSubscriptionPayment',
    tag: SPECIFICATION_TAG.INFO_ABOUT_SUBSCRIPTION_PAYMENT,
    slug: 'info-about-subscription-payment',
  },
  OTHER_PAYMENT_METHOD: {
    key: 'otherPaymentMethod',
    tag: SPECIFICATION_TAG.OTHER_PAYMENT_METHOD,
    slug: 'other-payment-method',
  },
  HOTMART_ACCOUNT_ACCESS: {
    key: 'hotmartAccountAccess',
    tag: SPECIFICATION_TAG.HOTMART_ACCOUNT_ACCESS,
    slug: 'hotmart-account-access',
  },
  CHANGE_IN_REGISTRATION_INFORMATION: {
    key: 'changeInRegistrationInformation',
    tag: SPECIFICATION_TAG.CHANGE_IN_REGISTRATION_INFORMATION,
    slug: 'change-in-registration-information',
  },
  REFUND_OR_CANCELLATION: {
    key: 'refundOrCancellation',
    tag: SPECIFICATION_TAG.REFUND_OR_CANCELLATION,
    slug: 'refund-or-cancellation',
  },
  CONTENT_OR_CERTIFICATE: {
    key: 'contentOrCertificate',
    tag: SPECIFICATION_TAG.CONTENT_OR_CERTIFICATE,
    slug: 'content-or-certificate',
  },
  CERTIFICATE_EMISSION: {
    key: 'certificateEmission',
    tag: SPECIFICATION_TAG.CERTIFICATE_EMISSION,
    slug: 'certificate-emission',
  },
  BRAZILIAN_CURRENCIE: {
    key: 'brazilianCurrencie',
    tag: SPECIFICATION_TAG.BRAZILIAN_CURRENCIE,
    slug: 'brazilian-currencie',
  },
  OTHER_CURRENCIES: {
    key: 'otherCurrencies',
    tag: SPECIFICATION_TAG.OTHER_CURRENCIES,
    slug: 'other-currencies',
  },
  MY_PRODUCT_CONTENT: {
    key: 'myProductContent',
    tag: SPECIFICATION_TAG.MY_PRODUCT_CONTENT,
    slug: 'my-product-content',
  },
  MY_PRODUCT_CONTENT_QUESTIONS: {
    key: 'myProductContent',
    tag: SPECIFICATION_TAG.MY_PRODUCT_CONTENT_QUESTIONS,
    slug: 'my-product-content-questions',
  },
  ACCESS_DEADLINES: {
    key: 'accessDeadlines',
    tag: SPECIFICATION_TAG.ACCESS_DEADLINES,
    slug: 'access-deadlines',
  },
  ACCESS_DEADLINES_QUESTIONS: {
    key: 'accessDeadlines',
    tag: SPECIFICATION_TAG.ACCESS_DEADLINES_QUESTIONS,
    slug: 'access-deadlines-questions',
  },
  BONUS_OR_PHYSICAL_PRODUCT: {
    key: 'bonusOrPhysicalProduct',
    tag: SPECIFICATION_TAG.BONUS_OR_PHYSICAL_PRODUCT,
    slug: 'bonus-or-physical-product',
  },
  BONUS_OR_PHYSICAL_PRODUCT_QUESTIONS: {
    key: 'bonusOrPhysicalProduct',
    tag: SPECIFICATION_TAG.BONUS_OR_PHYSICAL_PRODUCT_QUESTIONS,
    slug: 'bonus-or-physical-product-questions',
  },
  BONUS_CONTENT: {
    key: 'bonusContent',
    tag: SPECIFICATION_TAG.BONUS_CONTENT,
    slug: 'bonus-content-delivery',
  },
  SOCIAL_MEDIA_GROUPS: {
    key: 'socialMediaGroups',
    tag: SPECIFICATION_TAG.SOCIAL_MEDIA_GROUPS,
    slug: 'social-media-groups',
  },
  SOCIAL_MEDIA_GROUPS_QUESTIONS: {
    key: 'socialMediaGroups',
    tag: SPECIFICATION_TAG.SOCIAL_MEDIA_GROUPS_QUESTIONS,
    slug: 'social-media-groups-questions',
  },
  PAYMENT_INFORMATION: {
    key: 'paymentInformation',
    tag: SPECIFICATION_TAG.PAYMENT_INFORMATION,
    slug: 'payment-information',
  },
  CANT_MAKE_PURCHASE: {
    key: 'cantMakePurchase',
    tag: SPECIFICATION_TAG.CANT_MAKE_PURCHASE,
    slug: 'cant-make-purchase',
  },
  SMART_SUBSCRIPTIONS_PAYMENTS: {
    key: 'smartSubscriptionsPayments',
    tag: SPECIFICATION_TAG.SMART_SUBSCRIPTIONS_PAYMENTS,
    slug: 'smart-subscriptions-payments',
  },
  CHANGE_REGISTRATION: {
    key: 'changeRegistration',
    tag: SPECIFICATION_TAG.CHANGE_REGISTRATION,
    slug: 'change-registration',
  },
  CHANGE_REGISTRATION_COUNTRY: {
    key: 'changeRegistrationCountry',
    tag: SPECIFICATION_TAG.CHANGE_REGISTRATION_COUNTRY,
    slug: 'change-registration-country',
  },
  GENERAL_PROBLEMS_WITH_REGISTRATION_DATA: {
    key: 'generalProblemsWithRegistrationData',
    tag: SPECIFICATION_TAG.GENERAL_PROBLEMS_WITH_REGISTRATION_DATA,
    slug: 'general-problems-with-registration-data',
  },
  PROBLEMS_WITH_MY_BALANCE: {
    key: 'problemsWithMyBalance',
    tag: SPECIFICATION_TAG.PROBLEMS_WITH_MY_BALANCE,
    slug: 'problems-with-my-balance',
  },
  I_CANT_WITHDRAW: { key: 'iCantWithdraw', tag: SPECIFICATION_TAG.I_CANT_WITHDRAW, slug: 'i-cant-withdraw' },
  CHANGE_COMMISSION_BETWEEN_AFFILIATES: {
    key: 'changeCommissionBetweenAffiliates',
    tag: SPECIFICATION_TAG.CHANGE_COMMISSION_BETWEEN_AFFILIATES,
    slug: 'change-commission-between-affiliates',
  },
  HOTLEADS: { key: 'hotleads', tag: SPECIFICATION_TAG.HOTLEADS, slug: 'hotleads' },
  HOTLEADS_AFFILIATE: { key: 'hotleads', tag: SPECIFICATION_TAG.HOTLEADS_AFFILIATE, slug: 'hotleads-affiliate' },
  LISTBOSS: { key: 'listboss', tag: SPECIFICATION_TAG.LISTBOSS, slug: 'listboss' },
  LINK_SHORTENER: {
    key: 'linkShortener',
    tag: SPECIFICATION_TAG.LINK_SHORTENER,
    slug: 'link-shortener',
  },
  CHECKOUT: { key: 'checkout', tag: SPECIFICATION_TAG.CHECKOUT, slug: 'checkout' },
  CHECKOUT_AFFILIATE: { key: 'checkout', tag: SPECIFICATION_TAG.CHECKOUT_AFFILIATE, slug: 'checkout-affiliate' },
  CHECKIN: { key: 'checkin', tag: SPECIFICATION_TAG.CHECKIN, slug: 'checkin' },
  HOTMART_ADS: { key: 'hotmartAds', tag: SPECIFICATION_TAG.HOTMART_ADS, slug: 'hotmart-ads' },
  HOTMART_ASK: { key: 'hotmartAsk', tag: SPECIFICATION_TAG.HOTMART_ASK, slug: 'hotmart-ask' },
  MEMBERS_AREA: { key: 'membersArea', tag: SPECIFICATION_TAG.MEMBERS_AREA, slug: 'members-area' },
  INTEGRATIONS: { key: 'integrations', tag: SPECIFICATION_TAG.INTEGRATIONS, slug: 'integrations' },
  AFFILIATE_PROGRAM: { key: 'affiliateProgram', tag: SPECIFICATION_TAG.AFFILIATE_PROGRAM, slug: 'affiliate-program' },
  AFFILIATE_PROGRAM_PRODUCER: {
    key: 'affiliateProgram',
    tag: SPECIFICATION_TAG.AFFILIATE_PROGRAM_PRODUCER,
    slug: 'affiliate-program',
  },
  HOTLINK: { key: 'disclosureLinksManager', tag: SPECIFICATION_TAG.HOTLINK, slug: 'hotlink' },
  IMPORTING_STUDENTS: {
    key: 'importingStudents',
    tag: SPECIFICATION_TAG.IMPORTING_STUDENTS,
    slug: 'importing-students',
  },
  EMPLOYEE_CENTER: { key: 'employeeCenter', tag: SPECIFICATION_TAG.EMPLOYEE_CENTER, slug: 'employee-center' },
  TRACKING_PIXEL: { key: 'trackingPixel', tag: SPECIFICATION_TAG.TRACKING_PIXEL, slug: 'tracking-pixel' },
  TRACKING_PIXEL_AFFILIATE: {
    key: 'trackingPixel',
    tag: SPECIFICATION_TAG.TRACKING_PIXEL_AFFILIATE,
    slug: 'tracking-pixel-affiliate',
  },
  REPORTS_AND_CLICKS: {
    key: 'reportsAndClicks',
    tag: SPECIFICATION_TAG.REPORTS_AND_CLICKS,
    slug: 'reports-and-clicks',
  },
  CANT_ACCESS_ACCOUNT: {
    key: 'loginProblems',
    tag: SPECIFICATION_TAG.CANT_ACCESS_ACCOUNT,
    slug: 'login-problems',
  },
  HTV_CANT_ACCESS_ACCOUNT: {
    key: 'loginProblems',
    tag: SPECIFICATION_TAG.HTV_CANT_ACCESS_ACCOUNT,
    slug: 'htv-login-problems',
  },
  ERROR_MESSAGE_APPEARED: {
    key: 'errorMessageAppeared',
    tag: SPECIFICATION_TAG.ERROR_MESSAGE_APPEARED,
    slug: 'error-message-appeared',
  },
  ERROR_MESSAGE: {
    key: 'errorMessageAppeared',
    tag: SPECIFICATION_TAG.ERROR_MESSAGE,
    slug: 'error-message',
  },
  HTV_ERROR_MESSAGE_APPEARED: {
    key: 'errorMessageAppeared',
    tag: SPECIFICATION_TAG.HTV_ERROR_MESSAGE_APPEARED,
    slug: 'htv-error-message-appeared',
  },
  KNOW_ACCESS_MYCOURSE: {
    key: 'IDontKnowWhereAccessMyCourse',
    tag: SPECIFICATION_TAG.KNOW_ACCESS_MYCOURSE,
    slug: 'i-dont-know-where-access-my-course',
  },
  NOT_RECEIVEING_EMAILS: {
    key: 'INotReceivingEmailsFromHotmart',
    tag: SPECIFICATION_TAG.NOT_RECEIVEING_EMAILS,
    slug: 'i-not-receiving-emails-from-hotmart',
  },
  LOGIN_PROBLEMS: {
    key: 'loginProblems',
    tag: SPECIFICATION_TAG.LOGIN_PROBLEMS,
    slug: 'login-problems',
  },
  TROUBLE_ACESSING_CONTENT: {
    key: 'canNotAccessContent',
    tag: SPECIFICATION_TAG.TROUBLE_ACESSING_CONTENT,
    slug: 'can-not-access-content',
  },
  TROUBLE_VIDEO_PLAY: {
    key: 'videoPlaybackIssues',
    tag: SPECIFICATION_TAG.TROUBLE_VIDEO_PLAY,
    slug: 'video-playback-issues',
  },

  LEGAL_PLAGIARISM: {
    key: 'plagiarism',
    tag: SPECIFICATION_TAG.LEGAL_PLAGIARISM,
    slug: 'plagiarism',
  },
  TRADEMARK_VIOLATION: {
    key: 'trademarkViolation',
    tag: SPECIFICATION_TAG.TRADEMARK_VIOLATION,
    slug: 'trademarkViolation',
  },
  IMAGE_VIOLATION: {
    key: 'imageViolation',
    tag: SPECIFICATION_TAG.IMAGE_VIOLATION,
    slug: 'imageViolation',
  },
  RA_NOT_RECEIVEING_EMAILS: {
    key: 'INotReceivingEmailsFromHotmart',
    tag: SPECIFICATION_TAG.RA_NOT_RECEIVEING_EMAILS,
    slug: 'i-not-receiving-emails-from-hotmart',
  },

  TRANSFER_OWNERSHIP: {
    key: 'transferOwnership',
    tag: SPECIFICATION_TAG.TRANSFER_OWNERSHIP,
    slug: 'transfer-ownership',
  },
  OTHER: { key: 'other', tag: SPECIFICATION_TAG.OTHER, slug: 'other' },
  CANT_FIND_APP: {
    key: 'difficultyFindingApp',
    tag: SPECIFICATION_TAG.CANT_FIND_APP,
    slug: 'difficulty-finding-app',
  },
  FIND_OR_ACCESS_COURSES: {
    key: 'difficultyFindingAccessingCourses',
    tag: SPECIFICATION_TAG.FIND_OR_ACCESS_COURSES,
    slug: 'finding-accessing-courses',
  },
  COPRODUCER: {
    key: 'coProducer',
    tag: SPECIFICATION_TAG.COPRODUCER,
    slug: 'coproducer',
  },
  MARKETPLACE: {
    key: 'marketplace',
    tag: SPECIFICATION_TAG.MARKETPLACE,
    slug: 'marketplace',
  },
  HOTMART_ONE: {
    key: 'hotmartOne',
    tag: SPECIFICATION_TAG.HOTMART_ONE,
    slug: 'hotmart-one',
  },
  PRODUCT_REGISTRATION: {
    key: 'producerRegistration',
    tag: SPECIFICATION_TAG.PRODUCT_REGISTRATION,
    slug: 'producer-registration',
  },
  PRODUCER_ACHIEVEMENTS: {
    key: 'producerAchievements',
    tag: SPECIFICATION_TAG.PRODUCER_ACHIEVEMENTS,
    slug: 'producer-achievements',
  },
  PROOF_REFUND_REQUEST: {
    key: 'proofRefundRequest',
    tag: SPECIFICATION_TAG.PROOF_REFUND_REQUEST,
    slug: 'proof-refund-request',
  },
  GENERAL_QUESTIONS_OTHER: {
    key: 'other',
    tag: SPECIFICATION_TAG.GENERAL_QUESTIONS_OTHER,
    slug: 'general-questions-other',
  },
  LINK_CHECKOUT: {
    key: 'linkCheckout',
    tag: SPECIFICATION_TAG.LINK_CHECKOUT,
    slug: 'link-checkout',
  },
  LINK_CHECKOUT_AFFILIATE: {
    key: 'linkCheckout',
    tag: SPECIFICATION_TAG.LINK_CHECKOUT_AFFILIATE,
    slug: 'link-checkout-affiliate',
  },
  UNRELEASED_PRODUCT: {
    key: 'unreleasedProduct',
    tag: SPECIFICATION_TAG.UNRELEASED_PRODUCT,
    slug: 'unrelease-product',
  },
  HELP_REGISTER_PRODUCT: {
    key: 'helpRegisterProduct',
    tag: SPECIFICATION_TAG.HELP_REGISTER_PRODUCT,
    slug: 'help-register-product',
  },
  BLUEPRINT_ANALYSIS: {
    key: 'blueprintAnalysis',
    tag: SPECIFICATION_TAG.BLUEPRINT_ANALYSIS,
    slug: 'blueprint-analysis',
  },
  PRODUCT_REVIEW_LAUNCH: {
    key: 'productReviewLaunch',
    tag: SPECIFICATION_TAG.PRODUCT_REVIEW_LAUNCH,
    slug: 'product-review-launch',
  },
  DOUBTS: {
    key: 'doubts',
    tag: SPECIFICATION_TAG.DOUBTS,
    slug: 'doubts',
  },
  FIRST_STEPS: {
    key: 'firstSteps',
    tag: SPECIFICATION_TAG.FIRST_STEPS,
    slug: 'questions-affiliate-first-steps',
  },
  SALES_STRATEGIES: {
    key: 'salesStrategies',
    tag: SPECIFICATION_TAG.SALES_STRATEGIES,
    slug: 'questions-affiliate-sales-strategies',
  },
  PRODUCER_CONTACT: {
    key: 'producerContact',
    tag: SPECIFICATION_TAG.PRODUCER_CONTACT,
    slug: 'questions-affiliate-producer-contact',
  },
  AFFILIATE_PRODUCER_CANT_ACCESS_ACCOUNT: {
    key: 'loginProblems',
    tag: SPECIFICATION_TAG.AFFILIATE_PRODUCER_CANT_ACCESS_ACCOUNT,
    slug: 'login-problems',
  },
  AFFILIATE_PRODUCER_ERROR_MESSAGE_APPEARED: {
    key: 'errorMessageAppeared',
    tag: SPECIFICATION_TAG.AFFILIATE_PRODUCER_ERROR_MESSAGE_APPEARED,
    slug: 'affiliate-error-message-appeared',
  },
  AFFILIATE_PRODUCER_CANT_RECEIVING_EMAIL_HOTMART: {
    key: 'canNotReceivingEmailsFromHotmart',
    tag: SPECIFICATION_TAG.AFFILIATE_PRODUCER_CANT_RECEIVING_EMAIL_HOTMART,
    slug: 'can-not-receiving-email-from-hotmart',
  },
  AFFILIATE_PRODUCER_CANT_REGISTER_DOCUMENT: {
    key: 'canNotRegisterDocument',
    tag: SPECIFICATION_TAG.AFFILIATE_PRODUCER_CANT_REGISTER_DOCUMENT,
    slug: 'can-not-receiving-document',
  },
  AFFILIATE_PRODUCER_ACCOUNT_BLOCKING_AND_REACTIVATION: {
    key: 'accountBlockingAndReactivation',
    tag: SPECIFICATION_TAG.AFFILIATE_PRODUCER_ACCOUNT_BLOCKING_AND_REACTIVATION,
    slug: 'account-blocking-and-reactivation',
  },
  AFFILIATE_PRODUCER_EMANCIPATE_MINOR: {
    key: 'iAmEmancipatedMinor',
    tag: SPECIFICATION_TAG.AFFILIATE_PRODUCER_EMANCIPATE_MINOR,
    slug: 'i-am-emancipate-minor',
  },
  EXTERNAL_PLATFORMS: {
    key: 'externalPlatforms',
    tag: SPECIFICATION_TAG.EXTERNAL_PLATFORMS,
    slug: 'external-platforms',
  },
  DISCLOSURE_LINKS: {
    key: 'disclosureLink',
    tag: SPECIFICATION_TAG.DISCLOSURE_LINKS,
    slug: 'disclosure-links',
  },
  LEAD_COUNT: {
    key: 'leadCount',
    tag: SPECIFICATION_TAG.LEAD_COUNT,
    slug: 'lead-count',
  },
  LEAD_DATA: {
    key: 'leadData',
    tag: SPECIFICATION_TAG.LEAD_DATA,
    slug: 'lead-data',
  },
}
