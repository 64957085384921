export default ctx => () => {
  const { $axios, store } = ctx

  return {
    async retrieve(key) {
      const response = await $axios.get(`/toggleSwitch?key=${key}`)
      store.dispatch('featureToggle/setFeatureToggle', { key, value: response.enabled })

      return response
    },
  }
}
