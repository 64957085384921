import { TOGGLE } from '@/enum/toggles'
import waitForElement from '@/utils/waitForElement'

export default {
  async initChatbotStore({ commit, state, dispatch }) {
    if (state.initialized) return
    commit('SET_INITIALIZED', true)

    const element = await waitChatbotElement()
    commit('SET_ELEMENT', element)

    addResizeListener(state.element, commit)
    addClickListener(state.element, commit)

    dispatch('retrieves')
  },

  async setWelcomeMessageToggle({ commit }, payload) {
    commit('SET_WELCOME_MESSAGE_TOGGLE', payload)
  },

  async retrieves({ commit }) {
    const [{ enabled: welcomeMessageToggle }, { enabled: contactUsChatOpenToggle }] = await Promise.all([
      this.$toggle.retrieve(TOGGLE.CHATBOT_WELCOME_MSG),
      this.$toggle.retrieve(TOGGLE.CONTACT_US_CHAT_OPEN),
    ])
    commit('SET_WELCOME_MESSAGE_TOGGLE', welcomeMessageToggle)
    commit('SET_CONTACT_US_CHAT_OPEN_TOGGLE', contactUsChatOpenToggle)
  },

  async openChat({ state, dispatch }) {
    if (state.open) return
    dispatch('toggleChat')
  },

  async closeChat({ commit, state }) {
    if (!state.open) return
    dispatch('toggleChat')
  },

  async toggleChat({ commit, state }) {
    const element = await waitChatbotElement()
    if (element) {
      element.click()
      commit('SET_OPEN', !state.open)
    }
  },

  async setVisible({ commit, state }, payload) {
    if (!process.browser) return
    toggleBlipChatVisibility(payload)
    commit('SET_VISIBLE', payload)
  },

  async toggleVisibility({ commit, state }) {
    const visible = !state.visible
    commit('SET_VISIBLE', visible)
    toggleBlipChatVisibility(visible)
  },
}

/**
 * Wait for chatbot element to be available and return it.
 * @returns {Promise<HTMLElement>}
 */
async function waitChatbotElement() {
  return waitForElement('#blip-chat-open-iframe')
}

/**
 * Add a resize listener to the chatbot element. This will update the store with the new size and position.
 * @param {HTMLElement} element - The chatbot element.
 * @param {Function} commit - The vuex commit function.
 */
function addResizeListener(element, commit) {
  if (!element) return
  const handleResize = () => {
    const { x, y } = element.getBoundingClientRect()
    commit('SET_X', x)
    commit('SET_Y', y)
    commit('SET_WIDTH', element.offsetWidth)
    commit('SET_HEIGHT', element.offsetHeight)
  }

  window.addEventListener('resize', handleResize)
  handleResize()
}

/**
 * Add a click listener to the chatbot element. This will update the store with the new open state.
 * @param {HTMLElement} element - The chatbot element.
 * @param {Function} commit - The vuex commit function.
 */
function addClickListener(element, commit) {
  if (!element) return
  const handleClick = () => {
    if (window?.blipClient) {
      commit('SET_OPEN', window.blipClient.widget.isOpen)
    }
  }
  element.addEventListener('click', handleClick)
}

function toggleBlipChatVisibility(show) {
  if (window?.blipClient) {
    window.blipClient.widget.blipChatContainer?.classList.toggle('_d-none', !show)
  }
}
