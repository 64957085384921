import microfrontRenderer from '@hotmart/microfront-renderer'

const BLIP_CHAT_ENTER_EVENT = 'enter-blip-widget'

export default ({ app, $config, $gtm }, inject) => {
  const handleBlipChatEnterEvent = () => {
    $gtm.push({
      event: 'interaction',
      event_category: 'HelpCenter:Chat',
      event_action: 'Click:Open:Chat',
      event_label: 'Floating button',
    })
    dataLayer.push({
      event: 'custom_event',
      custom_event_name: 'click_chatbot',
      event_details: 'Open::Floating button',
    })
  }
  const addBlipChatListener = () => {
    if (typeof window !== 'undefined') {
      document.addEventListener(BLIP_CHAT_ENTER_EVENT, handleBlipChatEnterEvent)
    }
  }
  addBlipChatListener()
  inject('microfrontRenderer', () => {
    const userId = app.store.getters['casUser/userCas']?.profile?.id
    const url = $config.blipChatHost
    microfrontRenderer({
      url: `${url}/index.bundle.js`,
      globalName: '@hotmart/app-blip-chat',
      containerId: 'app-blip-chat',
      props: {
        baseUrl: url,
        language: app.i18n.locale,
        userId,
        gestAccess: !userId,
        domain: 'HELPCENTER',
      },
    })
  })
}
