import isEmpty from 'lodash/isEmpty'
import LocaleService from '~/services/LocaleService'
class LocaleMiddleware {
  constructor({ app, req, store, route, params, redirect, error }) {
    this.app = app
    this.req = req
    this.store = store
    this.route = route
    this.params = params
    this.redirect = redirect
    this.error = error

    this.localeService = new LocaleService({ app, store, route, req, params })

    this.handle()
  }

  removeDuplicateSlashesAndRedirect() {
    if (/\/{2,}/g.test(this.route.path)) this.redirect(this.route.path.replace(/\/+/g, '/'))
  }

  handle() {
    this.removeDuplicateSlashesAndRedirect()
    this.setLocale()
    this.redirectLocale()
  }

  setLocale() {
    const localeFromService = this.localeService.getLocale()
    this.localeService.setLocale(localeFromService)
  }

  redirectLocale() {
    if (!/^locale(-)?/.test(this.route.name) && this.route.name !== null) return

    let { locale } = this.route.params
    let { path, query } = this.route

    if (locale) path = path.replace(`/${locale}`, '')

    const newLocale = this.localeService.getLocale()
    if (newLocale && !locale) path = path.replace(`/${newLocale}`, '')

    if (newLocale === locale) return

    query = isEmpty(query) ? '' : `?${new URLSearchParams(query).toString()}`

    const parsedUrl = `/${newLocale}${path}${query}`.replace(/\/$/, '')

    this.redirect(parsedUrl)
  }
}

export default ({ isHMR, app, req, store, route, params, redirect, error }) => {
  if (isHMR) return

  new LocaleMiddleware({ app, req, store, route, params, redirect, error })
}
