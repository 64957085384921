import { TOGGLE } from '~/enum/toggles'

export default () => {
  return {
    [TOGGLE.CHATBOT_WELCOME_MSG]: false,
    [TOGGLE.CONTACT_US_CHAT_OPEN]: false,
    [TOGGLE.FAQGPT_SUMMARY]: false,
    [TOGGLE.FAQGPT_SUMMARY_STREAM]: false,
    [TOGGLE.USER_FEEDBACK]: false,
    [TOGGLE.CHATBOT_CHANGE_EMAIL]: false,
  }
}
